.header-logo {
    width: 75%;
}

a[href] {
    color: #000;
}

.bg-primary{
    background-color: $primary;
    color: #FFF !important;
}

.main-page{
    min-height: 70vh !important;
}

.mat-typography
{
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-typography h2
{
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}
.mat-typography h3
{
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: $white-color;
  opacity: 0.9;
  z-index: 9999!important;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.loader{
  margin: auto;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  position: fixed !important;
  z-index: 9999;
}